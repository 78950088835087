// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-events-page-js": () => import("./../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-giving-page-js": () => import("./../../src/templates/giving-page.js" /* webpackChunkName: "component---src-templates-giving-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-livestream-page-js": () => import("./../../src/templates/livestream-page.js" /* webpackChunkName: "component---src-templates-livestream-page-js" */),
  "component---src-templates-ministries-page-js": () => import("./../../src/templates/ministries-page.js" /* webpackChunkName: "component---src-templates-ministries-page-js" */),
  "component---src-templates-sermons-page-js": () => import("./../../src/templates/sermons-page.js" /* webpackChunkName: "component---src-templates-sermons-page-js" */)
}

